import React from "react";

import { ParallaxProvider } from "react-scroll-parallax";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <ParallaxProvider>
    <Layout showHeader={true}>
      <SEO title="About" />
      <div>you are on the about page</div>
    </Layout>
  </ParallaxProvider>
);

export default IndexPage;
